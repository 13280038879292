export default {
  translation: {
    common: {
      delete: 'Xóa',
      deleteModalTitle: 'Bạn có chắc chắn muốn xóa mục này?',
      ok: 'Có',
      cancel: 'Không',
      total: 'Tổng cộng',
      rename: 'Đổi tên',
      name: 'Tên',
      save: 'Lưu',
      namePlaceholder: 'Vui lòng nhập tên',
      next: 'Tiếp theo',
      create: 'Tạo mới',
      edit: 'Sửa',
      upload: 'Tải lên',
      english: 'Tiếng Anh',
      chinese: 'Tiếng Trung giản thể',
      traditionalChinese: 'Tiếng Trung phồn thể',
      language: 'Ngôn ngữ',
      languageMessage: 'Vui lòng chọn ngôn ngữ của bạn!',
      languagePlaceholder: 'chọn ngôn ngữ của bạn',
      copy: 'Sao chép',
      copied: 'Đã sao chép',
      comingSoon: 'Sắp ra mắt',
      download: 'Tải xuống',
      close: 'Đóng',
      preview: 'Xem trước',
      move: 'Di chuyển',
      warn: 'Cảnh báo',
      action: 'Hành động',
      s: 'S',
      pleaseSelect: 'Vui lòng chọn',
      pleaseInput: 'Vui lòng nhập',
      submit: 'Gửi',
      vietnamese: 'Tiếng  việt',
      spanish: 'Tiếng Tây Ban Nha',
      japanese: 'Tiếng Nhật',
    },
    login: {
      login: 'Đăng nhập',
      signUp: 'Đăng ký',
      loginDescription: 'Rất vui được gặp lại bạn!',
      registerDescription: 'Rất vui được đón chào bạn!',
      emailLabel: 'Email',
      emailPlaceholder: 'Vui lòng nhập email',
      passwordLabel: 'Mật khẩu',
      passwordPlaceholder: 'Vui lòng nhập mật khẩu',
      rememberMe: 'Ghi nhớ tôi',
      signInTip: 'Chưa có tài khoản?',
      signUpTip: 'Đã có tài khoản?',
      nicknameLabel: 'Biệt danh',
      nicknamePlaceholder: 'Vui lòng nhập biệt danh',
      register: 'Tạo tài khoản',
      continue: 'Tiếp tục',
      title: 'Bắt đầu xây dựng trợ lý ảo của bạn.',
      description:
        'Đăng ký miễn phí để khám phá công nghệ RAG hàng đầu. Tạo cơ sở kiến thức và AI để trao quyền cho doanh nghiệp của bạn.',
      review: 'từ hơn 500 đánh giá',
    },
    header: {
      knowledgeBase: 'Cơ sở kiến thức',
      chat: 'Chat',
      register: 'Đăng ký',
      signin: 'Đăng nhập',
      home: 'Trang chủ',
      setting: 'Cài đặt người dùng',
      logout: 'Đăng xuất',
      fileManager: 'Quản lý tệp',
      flow: 'Agent',
      search: 'Tìm kiếm',
    },
    knowledgeList: {
      welcome: 'Chào mừng trở lại',
      description: 'Chúng ta sẽ sử dụng cơ sở kiến thức nào hôm nay?',
      createKnowledgeBase: 'Tạo cơ sở kiến thức',
      name: 'Tên',
      namePlaceholder: 'Vui lòng nhập tên!',
      doc: 'Tài liệu',
      searchKnowledgePlaceholder: 'Tìm kiếm',
      noMoreData: 'Tất cả chỉ có thế, không còn gì nữa',
    },
    knowledgeDetails: {
      dataset: 'Dữ liệu',
      testing: 'Kiểm tra truy hồi',
      files: 'Các tệp',
      configuration: 'Cấu hình',
      name: 'Tên',
      namePlaceholder: 'Vui lòng nhập tên!',
      doc: 'Tài liệu',
      datasetDescription:
        ' Câu hỏi và câu trả lời chỉ có thể được trả lời sau khi phân tích cú pháp thành công.',
      addFile: 'Thêm tệp',
      searchFiles: 'Tìm kiếm tệp của bạn',
      localFiles: 'Tệp cục bộ',
      emptyFiles: 'Tạo tệp trống',
      webCrawl: 'Web Crawl',
      chunkNumber: 'Số lượng khối',
      uploadDate: 'Ngày tải lên',
      chunkMethod: 'Phương thức khối',
      enabled: 'Bật',
      disabled: 'Tắt',
      action: 'Hành động',
      parsingStatus: 'Trạng thái phân tích cú pháp',
      processBeginAt: 'Bắt đầu xử lý lúc',
      processDuration: 'Thời gian xử lý',
      progressMsg: 'Thông báo tiến trình',
      testingDescription:
        'Thực hiện kiểm tra truy hồi để kiểm tra xem RAGFlow có thể phục hồi nội dung mong muốn cho LLM không.',
      similarityThreshold: 'Ngưỡng tương đồng',
      similarityThresholdTip:
        'RAGFlow sử dụng kết hợp giữa độ tương đồng từ khóa được trọng số và độ tương đồng vectơ cosin được trọng số hoặc kết hợp giữa độ tương đồng từ khóa được trọng số và điểm xếp hạng được tính lại trong quá trình truy hồi. Tham số này đặt ngưỡng cho sự tương đồng giữa truy vấn của người dùng và các khối. Bất kỳ khối nào có điểm tương đồng dưới ngưỡng này sẽ bị loại khỏi kết quả.',
      vectorSimilarityWeight: 'Trọng số tương đồng từ khóa',
      vectorSimilarityWeightTip:
        'Cài đặt trọng số của độ tương đồng từ khóa trong điểm tương đồng kết hợp, được sử dụng với độ tương đồng vectơ cosin hoặc với điểm xếp hạng được tính lại. Tổng của hai trọng số phải bằng 1.0.',
      testText: 'Văn bản kiểm tra',
      testTextPlaceholder: 'Nhập câu hỏi của bạn tại đây!',
      testingLabel: 'Kiểm tra',
      similarity: 'Giống nhau lai',
      termSimilarity: 'Giống nhau về thuật ngữ',
      vectorSimilarity: 'Giống nhau về vectơ',
      hits: 'Số lượt truy cập',
      view: 'Xem',
      filesSelected: 'Các tệp được chọn',
      upload: 'Tải lên',
      run: 'Chạy',
      runningStatus0: 'UNSTART',
      runningStatus1: 'Parsing',
      runningStatus2: 'CANCEL',
      runningStatus3: 'SUCCESS',
      runningStatus4: 'FAIL',
      pageRanges: 'Phạm vi trang',
      pageRangesTip:
        'Phạm vi trang: Xác định các phạm vi trang cần phân tích cú pháp. Các trang không nằm trong các phạm vi này sẽ bị bỏ qua.',
      fromPlaceholder: 'từ',
      fromMessage: 'Thiếu số trang bắt đầu',
      toPlaceholder: 'đến',
      toMessage: 'Thiếu số trang kết thúc (được loại trừ)',
      layoutRecognize: 'Nhận dạng bố cục',
      layoutRecognizeTip:
        'Sử dụng các mô hình trực quan để phân tích bố cục nhằm xác định tốt hơn cấu trúc tài liệu, tìm vị trí của tiêu đề, khối văn bản, hình ảnh và bảng. Nếu không có tính năng này, chỉ có thể lấy được văn bản thuần của PDF.',
      taskPageSize: 'Kích thước trang tác vụ',
      taskPageSizeMessage: 'Vui lòng nhập kích thước trang tác vụ của bạn!',
      taskPageSizeTip: `Nếu sử dụng nhận dạng bố cục, tệp PDF sẽ được chia thành các nhóm trang liên tiếp. Phân tích bố cục sẽ được thực hiện song song giữa các nhóm để tăng tốc độ xử lý. 'Kích thước trang tác vụ' xác định kích thước của các nhóm. Kích thước trang càng lớn, khả năng chia tách văn bản liên tục giữa các trang thành các khối khác nhau càng thấp.`,
      addPage: 'Thêm trang',
      greaterThan: 'Giá trị hiện tại phải lớn hơn!',
      greaterThanPrevious: 'Giá trị hiện tại phải lớn hơn giá trị trước đó!',
      selectFiles: 'Chọn tệp',
      changeSpecificCategory: 'Thay đổi danh mục cụ thể',
      uploadTitle: 'Nhấp hoặc kéo thả tệp vào khu vực này để tải lên',
      uploadDescription:
        'Hỗ trợ tải lên đơn hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu công ty hoặc các tệp bị cấm khác.',
      chunk: 'Khối',
      bulk: 'Hàng loạt',
      cancel: 'Hủy bỏ',
      rerankModel: 'Mô hình xếp hạng lại',
      rerankPlaceholder: 'Vui lòng chọn',
      rerankTip: `Nếu để trống, RAGFlow sẽ sử dụng kết hợp giữa độ tương đồng từ khóa được trọng số và độ tương đồng vectơ cosin được trọng số; nếu chọn mô hình xếp hạng lại, điểm xếp hạng được tính lại sẽ thay thế độ tương đồng vectơ cosin được trọng số.`,
      topK: 'Top-K',
      topKTip: `K khối sẽ được đưa vào các mô hình xếp hạng lại.`,
      delimiter: 'Giới hạn',
      html4excel: 'Excel sang HTML',
      html4excelTip: `Excel sẽ được phân tích cú pháp thành bảng HTML hay không. Nếu là FALSE, mỗi hàng trong Excel sẽ được tạo thành một khối.`,
      autoKeywords: 'Từ khóa tự động',
      autoKeywordsTip: `Trích xuất N từ khóa cho mỗi khối để tăng thứ hạng của chúng cho các truy vấn chứa các từ khóa đó. Bạn có thể kiểm tra hoặc cập nhật các từ khóa đã thêm cho một khối từ danh sách khối. Lưu ý rằng các token bổ sung sẽ được tiêu thụ bởi LLM được chỉ định trong 'Cài đặt mô hình hệ thống'.`,
      autoQuestions: 'Câu hỏi tự động',
      autoQuestionsTip: `Trích xuất N câu hỏi cho mỗi khối để tăng thứ hạng của chúng cho các truy vấn chứa các câu hỏi đó. Bạn có thể kiểm tra hoặc cập nhật các câu hỏi đã thêm cho một khối từ danh sách khối. Tính năng này sẽ không làm gián đoạn quá trình phân khối nếu xảy ra lỗi, ngoại trừ việc nó có thể thêm kết quả trống vào khối gốc. Lưu ý rằng các token bổ sung sẽ được tiêu thụ bởi LLM được chỉ định trong 'Cài đặt mô hình hệ thống'.`,
      delimiterTip: `Hỗ trợ nhiều ký tự phân cách, và các ký tự phân cách nhiều ký tự được bao bọc bởi dấu . Ví dụ: nếu được cấu hình như thế này: "##"; thì văn bản sẽ được phân tách bởi dấu xuống dòng, hai dấu # và dấu chấm phẩy, sau đó được lắp ráp theo kích thước của "số token".`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'Cập nhật cấu hình cơ sở kiến thức của bạn tại đây, đặc biệt là phương thức phân khối.',
      name: 'Tên cơ sở kiến thức',
      photo: 'Ảnh cơ sở kiến thức',
      description: 'Mô tả',
      language: 'Ngôn ngữ',
      languageMessage: 'Vui lòng nhập ngôn ngữ của bạn!',
      languagePlaceholder: 'Vui lòng nhập ngôn ngữ của bạn!',
      permissions: 'Quyền hạn',
      embeddingModel: 'Mô hình nhúng',
      chunkTokenNumber: 'Số token khối',
      chunkTokenNumberMessage: 'Số token khối là bắt buộc',
      embeddingModelTip:
        'Mô hình chuyển đổi các khối thành các nhúng. Nó không thể được thay đổi sau khi cơ sở kiến thức có các khối. Để chuyển sang mô hình nhúng khác, bạn phải xóa tất cả các khối trong cơ sở kiến thức.',
      permissionsTip:
        'Nếu được đặt thành "Đội", tất cả các thành viên trong nhóm sẽ có thể quản lý cơ sở kiến thức.',
      chunkTokenNumberTip:
        'Nó đặt ngưỡng token cho một khối. Một đoạn văn có ít token hơn ngưỡng này sẽ được kết hợp với đoạn văn tiếp theo cho đến khi số lượng token vượt quá ngưỡng, tại thời điểm đó, một khối được tạo.',
      chunkMethod: 'Phương thức khối',
      chunkMethodTip: 'Mẹo ở bên phải.',
      upload: 'Tải lên',
      english: 'Tiếng Anh',
      chinese: 'Tiếng Trung',
      embeddingModelPlaceholder: 'Vui lòng chọn một mô hình nhúng',
      chunkMethodPlaceholder: 'Vui lòng chọn một phương thức phân khối',
      save: 'Lưu',
      me: 'Chỉ tôi',
      team: 'Nhóm',
      cancel: 'Hủy bỏ',
      methodTitle: 'Mô tả phương thức phân khối',
      methodExamples: 'Ví dụ',
      methodExamplesDescription:
        'Các ảnh chụp màn hình sau được cung cấp để minh họa.',
      dialogueExamplesTitle: 'Ví dụ hội thoại',
      methodEmpty: 'Mô tả bằng hình ảnh các danh mục cơ sở kiến thức',
      book: `<p>Các định dạng tệp được hỗ trợ là <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Đối với mỗi sách trong PDF, vui lòng đặt <i>phạm vi trang</i> để loại bỏ thông tin không mong muốn và giảm thời gian phân tích.</p>`,
      laws: `<p>Các định dạng tệp được hỗ trợ là <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Các tài liệu pháp lý thường tuân theo định dạng viết nghiêm ngặt. Chúng tôi sử dụng tính năng văn bản để xác định điểm phân chia. 
      </p><p>
      Khối có độ chi tiết nhất quán với 'ARTICLE', đảm bảo tất cả văn bản cấp trên được bao gồm trong khối.
      </p>`,
      manual: `<p>Chỉ hỗ trợ <b>PDF</b>.</p><p>
      Chúng tôi giả định rằng sách hướng dẫn có cấu trúc phân cấp theo mục, sử dụng tiêu đề mục thấp nhất làm đơn vị cơ bản để phân đoạn tài liệu. Do đó, các hình ảnh và bảng trong cùng một mục sẽ không bị tách rời, điều này có thể dẫn đến kích thước khối lớn hơn.
      </p>`,
      naive: `<p>Phương thức này phân đoạn tệp bằng phương pháp 'ngây thơ': </p>
      <p>
      <li>Sử dụng mô hình nhận dạng thị giác để chia các văn bản thành các phân đoạn nhỏ hơn.</li>
      <li>Sau đó, kết hợp các phân đoạn liền kề cho đến khi số lượng token vượt quá ngưỡng được chỉ định bởi 'Số token khối', tại thời điểm đó, một khối được tạo.</li></p>
      <p>Các định dạng tệp được hỗ trợ là <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>`,
      paper: `<p>Chỉ hỗ trợ tệp <b>PDF</b>.</p><p>
      Bài báo sẽ được chia theo các phần, chẳng hạn như <i>tóm tắt, 1.1, 1.2</i>. </p><p>
      Cách tiếp cận này cho phép LLM tóm tắt bài báo hiệu quả hơn và cung cấp các phản hồi toàn diện, dễ hiểu hơn. 
      Tuy nhiên, nó cũng làm tăng ngữ cảnh cho các cuộc hội thoại AI và tăng thêm chi phí tính toán cho LLM. Vì vậy, trong quá trình trò chuyện, hãy cân nhắc giảm giá trị của '<b>topN</b>'.</p>`,
      presentation: `<p>Các định dạng tệp được hỗ trợ là <b>PDF</b>, <b>PPTX</b>.</p><p>
      Mỗi trang trong slide được coi là một khối, với hình thu nhỏ của nó được lưu trữ.</p><p>
      <i>Phương thức phân khối này được tự động áp dụng cho tất cả các tệp PPT được tải lên, do đó bạn không cần phải chỉ định thủ công.</i></p>`,
      qa: `
      <p>
      Phương thức phân khối này hỗ trợ các định dạng tệp <b>EXCEL</b> và <b>CSV/TXT</b>.
      </p>
      <li>
      Nếu tệp ở định dạng <b>Excel</b>, nó phải chứa hai cột
      không có tiêu đề: một cột cho câu hỏi và cột còn lại cho câu trả lời, với
      cột câu hỏi đứng trước cột câu trả lời. Nhiều trang tính được chấp nhận, miễn là các cột được cấu trúc chính xác.
      </li>
      <li>
      Nếu tệp ở định dạng <b>CSV/TXT</b>, tệp phải được mã hóa UTF-8 với TAB làm dấu phân cách để phân tách câu hỏi và câu trả lời.
      </li>
      <p>
      <i>
      Các dòng văn bản không tuân theo các quy tắc trên sẽ bị bỏ qua và
      mỗi cặp Q&A sẽ được coi là một khối riêng biệt.
      </i>
      </p>
      `,
      resume: `<p>Các định dạng tệp được hỗ trợ là <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
      </p><p>
      Các bản lý lịch với nhiều hình thức khác nhau được phân tích cú pháp và tổ chức thành dữ liệu có cấu trúc để hỗ trợ nhà tuyển dụng tìm kiếm ứng viên.
      </p>`,
      table: `<p>Các định dạng tệp được hỗ trợ là <b>EXCEL</b> và <b>CSV/TXT</b>.</p><p>
      Dưới đây là một số điều kiện tiên quyết và mẹo:
      <ul>
      <li>Đối với tệp CSV hoặc TXT, dấu phân cách giữa các cột phải là <em><b>TAB</b></em>.</li>
      <li>Dòng đầu tiên phải là tiêu đề cột.</li>
      <li>Tiêu đề cột phải là các thuật ngữ có nghĩa để hỗ trợ sự hiểu biết của LLM của bạn.
      Cách tốt nhất là đặt các từ đồng nghĩa cạnh nhau được phân cách bằng dấu gạch chéo <i>'/'</i> và liệt kê các giá trị bằng ngoặc vuông, ví dụ: <i>'Giới tính/Giới tính (nam, nữ)'</i>.<p>
      Dưới đây là một số ví dụ về tiêu đề:
      <ol>
      <li>nhà cung cấp/nhà cung cấp<b>'TAB'</b>Màu sắc (Vàng, Xanh lam, Nâu)<b>'TAB'</b>Giới tính/Giới tính (nam, nữ)<b>'TAB'</b>kích thước (M, L, XL, XXL)</li>
      </ol>
      </li>
      <li>Mỗi hàng trong bảng sẽ được coi là một khối.</li>
      </ul>`,
      picture: `
      <p>Các tệp hình ảnh được hỗ trợ, với hỗ trợ video sắp ra mắt.</p><p>
      Phương pháp này sử dụng mô hình OCR để trích xuất văn bản từ hình ảnh.
      </p><p>
      Nếu văn bản được trích xuất bởi mô hình OCR được coi là không đủ, một LLM hình ảnh được chỉ định sẽ được sử dụng để cung cấp mô tả về hình ảnh.
      </p>`,
      one: `
      <p>Các định dạng tệp được hỗ trợ là <b>DOCX, EXCEL, PDF, TXT</b>.
      </p><p>
      Phương pháp này xử lý toàn bộ tài liệu thành một khối.
      </p><p>
      Áp dụng khi bạn yêu cầu LLM tóm tắt toàn bộ tài liệu, với điều kiện nó có thể xử lý được lượng ngữ cảnh đó.
      </p>`,
      knowledgeGraph: `<p>Các định dạng tệp được hỗ trợ là <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>
             
      <p>Cách tiếp cận này phân đoạn tệp bằng phương pháp 'ngây thơ'/'Tổng hợp'. Nó chia tài liệu thành các phân đoạn và sau đó kết hợp các phân đoạn liền kề cho đến khi số lượng token vượt quá ngưỡng được chỉ định bởi 'Số token khối', tại thời điểm đó, một khối được tạo.</p>
      <p>Các khối sau đó được đưa vào LLM để trích xuất các thực thể và mối quan hệ cho biểu đồ tri thức và sơ đồ tư duy.</p>
      <p>Đảm bảo bạn đã đặt <b>Loại thực thể</b>.</p>`,
      useRaptor: 'Sử dụng RAPTOR để cải thiện truy xuất',
      useRaptorTip:
        'Recursive Abstractive Processing for Tree-Organized Retrieval, xem https://huggingface.co/papers/2401.18059 để biết thêm thông tin',
      prompt: 'Nhắc nhở',
      promptTip: 'Nhắc nhở LLM được sử dụng để tóm tắt.',
      promptMessage: 'Nhắc nhở là bắt buộc',
      promptText: `Vui lòng tóm tắt các đoạn văn sau. Cẩn thận với các số, đừng bịa ra. Các đoạn văn như sau:
      {cluster_content}
      Nội dung ở trên là nội dung bạn cần tóm tắt.`,
      maxToken: 'Số token tối đa',
      maxTokenTip: 'Số lượng token tối đa để tóm tắt.',
      maxTokenMessage: 'Số token tối đa là bắt buộc',
      threshold: 'Ngưỡng',
      thresholdTip: 'Ngưỡng càng cao thì cụm càng ít.',
      thresholdMessage: 'Ngưỡng là bắt buộc',
      maxCluster: 'Cụm tối đa',
      maxClusterTip: 'Số lượng cụm tối đa.',
      maxClusterMessage: 'Cụm tối đa là bắt buộc',
      randomSeed: 'Hạt giống ngẫu nhiên',
      randomSeedMessage: 'Hạt giống ngẫu nhiên là bắt buộc',
      entityTypes: 'Loại thực thể',
      vietnamese: 'Tiếng Việt',
      pageRank: 'Xếp hạng trang',
      pageRankTip: `Điều này được sử dụng để tăng điểm liên quan. Điểm liên quan với tất cả các khối được truy xuất sẽ cộng với số này, Khi bạn muốn tìm kiếm cơ sở kiến ​​thức đã cho ở vị trí đầu tiên, hãy đặt điểm "Page Rank" cao hơn những điểm khác.`,
    },
    chunk: {
      chunk: 'Khối',
      bulk: 'Hàng loạt',
      selectAll: 'Chọn tất cả',
      enabledSelected: 'Bật đã chọn',
      disabledSelected: 'Tắt đã chọn',
      deleteSelected: 'Xóa đã chọn',
      search: 'Tìm kiếm',
      all: 'Tất cả',
      enabled: 'Đã bật',
      disabled: 'Đã tắt',
      keyword: 'Từ khóa',
      function: 'Chức năng',
      chunkMessage: 'Vui lòng nhập giá trị!',
      full: 'Toàn văn',
      ellipse: 'Elip',
      graph: 'Biểu đồ kiến thức',
      mind: 'Sơ đồ tư duy',
      question: 'Câu hỏi',
      questionTip:
        'Nếu có những câu hỏi được đưa ra, việc nhúng phần đó sẽ dựa trên những câu hỏi đó.',
    },
    chat: {
      newConversation: 'Cuộc trò chuyện mới',
      createAssistant: 'Tạo trợ lý',
      assistantSetting: 'Cài đặt trợ lý',
      promptEngine: 'Công cụ nhắc nhở',
      modelSetting: 'Cài đặt mô hình',
      chat: 'Chat',
      newChat: 'Chat mới',
      send: 'Gửi',
      sendPlaceholder: 'Nhắn tin cho Trợ lý...',
      chatConfiguration: 'Cấu hình Chat',
      chatConfigurationDescription:
        'Tại đây, hãy trang điểm cho một trợ lý chuyên dụng cho các cơ sở kiến thức đặc biệt của bạn! 💕',
      assistantName: 'Tên trợ lý',
      assistantNameMessage: 'Tên trợ lý là bắt buộc',
      namePlaceholder: 'ví dụ: Resume Jarvis',
      assistantAvatar: 'Avatar trợ lý',
      language: 'Ngôn ngữ',
      emptyResponse: 'Phản hồi trống',
      emptyResponseTip: `Nếu không tìm thấy gì với câu hỏi của người dùng trong cơ sở kiến thức, nó sẽ sử dụng điều này làm câu trả lời. Nếu bạn muốn LLM đưa ra ý kiến ​​riêng của mình khi không tìm thấy gì, hãy để trống.`,
      setAnOpener: 'Đặt lời mở đầu',
      setAnOpenerInitial: `Xin chào! Tôi là trợ lý của bạn, tôi có thể giúp gì cho bạn?`,
      setAnOpenerTip: 'Bạn muốn chào đón khách hàng của mình như thế nào?',
      knowledgeBases: 'Cơ sở kiến thức',
      knowledgeBasesMessage: 'Vui lòng chọn',
      knowledgeBasesTip: 'Chọn các cơ sở kiến thức liên kết.',
      system: 'Hệ thống',
      systemInitialValue: `Bạn là một trợ lý thông minh. Vui lòng tóm tắt nội dung của cơ sở kiến thức để trả lời câu hỏi. Vui lòng liệt kê dữ liệu trong cơ sở kiến thức và trả lời chi tiết. Khi tất cả nội dung cơ sở kiến thức không liên quan đến câu hỏi, câu trả lời của bạn phải bao gồm câu "Câu trả lời bạn đang tìm kiếm không được tìm thấy trong cơ sở kiến thức!" Câu trả lời cần xem xét lịch sử trò chuyện.
      Đây là cơ sở kiến thức:
      {knowledge}
      Trên đây là cơ sở kiến thức.`,
      systemMessage: 'Vui lòng nhập!',
      systemTip:
        'Hướng dẫn bạn cần LLM để làm theo khi LLM trả lời câu hỏi, chẳng hạn như thiết kế nhân vật, độ dài câu trả lời và ngôn ngữ trả lời, v.v.',
      topN: 'Top N',
      topNTip: `Không phải tất cả các khối có điểm tương đồng vượt quá 'ngưỡng tương đồng' sẽ được cung cấp cho LLMs. LLM chỉ có thể xem 'Top N' khối này.`,
      variable: 'Biến',
      variableTip: `Nếu bạn sử dụng API thoại, các biến có thể giúp bạn trò chuyện với khách hàng của mình bằng các chiến lược khác nhau.
      Các biến được sử dụng để điền vào phần 'Hệ thống' trong lời nhắc để cung cấp cho LLM một gợi ý.
      'knowledge' là một biến rất đặc biệt sẽ được điền bằng các khối được truy xuất.
      Tất cả các biến trong 'Hệ thống' phải được đặt trong dấu ngoặc nhọn.`,
      add: 'Thêm',
      key: 'Khóa',
      optional: 'Tùy chọn',
      operation: 'Thao tác',
      model: 'Mô hình',
      modelTip: 'Mô hình trò chuyện ngôn ngữ lớn',
      modelMessage: 'Vui lòng chọn!',
      freedom: 'Tự do',
      improvise: 'Tự do',
      precise: 'Chính xác',
      balance: 'Cân bằng',
      freedomTip: `'Chính xác' có nghĩa là LLM sẽ bảo thủ và trả lời câu hỏi của bạn một cách thận trọng. 'Tự do' có nghĩa là bạn muốn LLM nói chuyện nhiều và tự do. 'Cân bằng' là giữa thận trọng và tự do.`,
      temperature: 'Nhiệt độ',
      temperatureMessage: 'Nhiệt độ là bắt buộc',
      temperatureTip:
        'Tham số này điều khiển tính ngẫu nhiên của dự đoán của mô hình. Nhiệt độ thấp hơn khiến mô hình tự tin hơn về phản hồi của mình, trong khi nhiệt độ cao hơn khiến nó sáng tạo và đa dạng hơn.',
      topP: 'Top P',
      topPMessage: 'Top P là bắt buộc',
      topPTip:
        'Còn được gọi là “mẫu hạt nhân”, tham số này đặt ngưỡng để chọn một tập hợp nhỏ hơn các từ để lấy mẫu. Nó tập trung vào các từ có khả năng nhất, cắt bỏ các từ ít khả năng hơn.',
      presencePenalty: 'Phạt hiện diện',
      presencePenaltyMessage: 'Phạt hiện diện là bắt buộc',
      presencePenaltyTip:
        'Điều này khuyến khích mô hình không lặp lại cùng một thông tin bằng cách phạt các từ đã xuất hiện trong cuộc trò chuyện.',
      frequencyPenalty: 'Phạt tần suất',
      frequencyPenaltyMessage: 'Phạt tần suất là bắt buộc',
      frequencyPenaltyTip:
        'Tương tự như hình phạt hiện diện, điều này làm giảm xu hướng của mô hình lặp lại cùng một từ thường xuyên.',
      maxTokens: 'Token tối đa',
      maxTokensMessage: 'Token tối đa là bắt buộc',
      maxTokensTip:
        'Điều này đặt độ dài tối đa của đầu ra của mô hình, được đo bằng số lượng token (từ hoặc phần của từ).',
      maxTokensInvalidMessage: 'Vui lòng nhập số Token tối đa hợp lệ.',
      maxTokensMinMessage: 'Token tối đa không thể nhỏ hơn 0.',
      quote: 'Hiển thị Trích dẫn',
      quoteTip: 'Nguồn văn bản gốc có nên được hiển thị không?',
      selfRag: 'Tự RAG',
      selfRagTip:
        'Vui lòng tham khảo: https://huggingface.co/papers/2310.11511',
      overview: 'ID cuộc trò chuyện',
      pv: 'Số lượng tin nhắn',
      uv: 'Số người dùng hoạt động',
      speed: 'Tốc độ xuất token',
      tokens: 'Tiêu thụ số token',
      round: 'Số tương tác phiên',
      thumbUp: 'Sự hài lòng của khách hàng',
      preview: 'Xem trước',
      embedded: 'Nhúng',
      serviceApiEndpoint: 'Điểm cuối của API dịch vụ',
      apiKey: 'KHÓA API',
      apiReference: 'Tài liệu API',
      dateRange: 'Khoảng thời gian:',
      backendServiceApi: 'Máy chủ API',
      createNewKey: 'Tạo khóa mới',
      created: 'Được tạo',
      action: 'Hành động',
      embedModalTitle: 'Nhúng vào trang web',
      comingSoon: 'Sắp có',
      fullScreenTitle: 'Nhúng toàn màn hình',
      fullScreenDescription:
        'Nhúng iframe sau vào trang web của bạn ở vị trí mong muốn',
      partialTitle: 'Nhúng một phần',
      extensionTitle: 'Phần mở rộng Chrome',
      tokenError: 'Vui lòng tạo Token API trước tiên!',
      searching: 'đang tìm kiếm...',
      parsing: 'Phân tích cú pháp',
      uploading: 'Đang tải lên',
      uploadFailed: 'Tải lên thất bại',
      regenerate: 'Tạo lại',
      read: 'Đọc nội dung',
      tts: 'Chuyển văn bản thành giọng nói',
      ttsTip:
        'Để phát giọng nói bằng cách chuyển đổi giọng nói, vui lòng chọn TTS (mô hình chuyển đổi giọng nói) trong cài đặt trước.',
      relatedQuestion: 'Câu hỏi liên quan',
      answerTitle: 'TL',
      multiTurn: 'Tối ưu hóa đa lượt',
      multiTurnTip:
        'Trong các cuộc trò chuyện nhiều vòng, truy vấn vào cơ sở kiến thức được tối ưu hóa. Mô hình lớn sẽ được gọi để sử dụng thêm các token.',
      howUseId: 'Làm thế nào để sử dụng ID cuộc trò chuyện?',
      description: 'Mô tả về trợ lý',
      betaError: `"Beta API Token" không được để trống!`,
    },
    setting: {
      profile: 'Hồ sơ',
      profileDescription: 'Cập nhật ảnh và thông tin cá nhân của bạn tại đây.',
      maxTokens: 'Token tối đa',
      maxTokensMessage: 'Token tối đa là bắt buộc',
      maxTokensTip:
        'Điều này đặt độ dài tối đa của đầu ra của mô hình, được đo bằng số lượng token (từ hoặc phần của từ).',
      maxTokensInvalidMessage: 'Vui lòng nhập số Token tối đa hợp lệ.',
      maxTokensMinMessage: 'Token tối đa không thể nhỏ hơn 0.',
      password: 'Mật khẩu',
      passwordDescription:
        'Vui lòng nhập mật khẩu hiện tại của bạn để thay đổi mật khẩu.',
      model: 'Nhà cung cấp mô hình',
      modelDescription: 'Đặt tham số mô hình và KHÓA API ở đây.',
      team: 'Nhóm',
      system: 'Hệ thống',
      logout: 'Đăng xuất',
      api: 'API',
      username: 'Tên người dùng',
      usernameMessage: 'Vui lòng nhập tên người dùng của bạn!',
      photo: 'Ảnh của bạn',
      photoDescription: 'Điều này sẽ được hiển thị trên hồ sơ của bạn.',
      colorSchema: 'Sơ đồ màu',
      colorSchemaMessage: 'Vui lòng chọn sơ đồ màu của bạn!',
      colorSchemaPlaceholder: 'chọn sơ đồ màu của bạn',
      bright: 'Sáng',
      dark: 'Tối',
      timezone: 'Múi giờ',
      timezoneMessage: 'Vui lòng nhập múi giờ của bạn!',
      timezonePlaceholder: 'chọn múi giờ của bạn',
      email: 'Địa chỉ email',
      emailDescription: 'Sau khi đăng ký, E-mail không thể thay đổi.',
      currentPassword: 'Mật khẩu hiện tại',
      currentPasswordMessage: 'Vui lòng nhập mật khẩu của bạn!',
      newPassword: 'Mật khẩu mới',
      newPasswordMessage: 'Vui lòng nhập mật khẩu của bạn!',
      newPasswordDescription: 'Mật khẩu mới của bạn phải có hơn 8 ký tự.',
      confirmPassword: 'Xác nhận mật khẩu mới',
      confirmPasswordMessage: 'Vui lòng xác nhận mật khẩu của bạn!',
      confirmPasswordNonMatchMessage: 'Mật khẩu mới bạn nhập không khớp!',
      cancel: 'Hủy bỏ',
      addedModels: 'Các mô hình đã thêm',
      modelsToBeAdded: 'Các mô hình cần thêm',
      addTheModel: 'Thêm mô hình',
      apiKey: 'API-Key',
      apiKeyMessage:
        'Vui lòng nhập khóa API (đối với mô hình được triển khai cục bộ, bỏ qua điều này).',
      apiKeyTip:
        'Khóa API có thể được lấy bằng cách đăng ký nhà cung cấp LLM tương ứng.',
      showMoreModels: 'Hiển thị thêm mô hình',
      baseUrl: 'Base-Url',
      baseUrlTip:
        'Nếu khóa API của bạn từ OpenAI, chỉ cần bỏ qua nó. Bất kỳ nhà cung cấp trung gian nào khác sẽ cung cấp URL cơ sở này với khóa API.',
      modify: 'Sửa đổi',
      systemModelSettings: 'Cài đặt mô hình hệ thống',
      chatModel: 'Mô hình trò chuyện',
      chatModelTip:
        'LLM trò chuyện mặc định mà tất cả các cơ sở kiến thức mới tạo sẽ sử dụng.',
      embeddingModel: 'Mô hình nhúng',
      embeddingModelTip:
        'Mô hình nhúng mặc định mà tất cả các cơ sở kiến thức mới tạo sẽ sử dụng.',
      img2txtModel: 'Mô hình img2txt',
      img2txtModelTip:
        'Mô hình đa mô-đun mặc định mà tất cả các cơ sở kiến thức mới tạo sẽ sử dụng. Nó có thể mô tả hình ảnh hoặc video.',
      sequence2txtModel: 'Mô hình sequence2txt',
      sequence2txtModelTip:
        'Mô hình ASR mặc định mà tất cả các cơ sở kiến thức mới tạo sẽ sử dụng. Sử dụng mô hình này để chuyển đổi giọng nói thành văn bản tương ứng.',
      rerankModel: 'Mô hình xếp hạng lại',
      rerankModelTip: `Mô hình xếp hạng lại mặc định được sử dụng để xếp hạng lại các khối được truy xuất bởi các câu hỏi của người dùng.`,
      ttsModel: 'Mô hình TTS',
      ttsModelTip:
        'Mô hình TTS mặc định sẽ được sử dụng để tạo giọng nói trong quá trình trò chuyện theo yêu cầu.',
      workspace: 'Không gian làm việc',
      upgrade: 'Nâng cấp',
      addLlmTitle: 'Thêm LLM',
      modelName: 'Tên mô hình',
      modelID: 'ID mô hình',
      modelUid: 'UID mô hình',
      modelNameMessage: 'Vui lòng nhập tên mô hình của bạn!',
      modelType: 'Loại mô hình',
      modelTypeMessage: 'Vui lòng nhập loại mô hình của bạn!',
      addLlmBaseUrl: 'URL cơ sở',
      baseUrlNameMessage: 'Vui lòng nhập URL cơ sở của bạn!',
      vision: 'Có hỗ trợ Tầm nhìn không?',
      ollamaLink: 'Cách tích hợp {{name}}',
      FishAudioLink: 'Cách sử dụng FishAudio',
      TencentCloudLink: 'Cách sử dụng TencentCloud ASR',
      volcModelNameMessage: 'Vui lòng nhập tên mô hình của bạn!',
      addEndpointID: 'EndpointID của mô hình',
      endpointIDMessage: 'Vui lòng nhập EndpointID của mô hình của bạn',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Vui lòng nhập ARK_API_KEY của bạn',
      addBedrockEngineAK: 'KHÓA TRUY CẬP',
      bedrockAKMessage: 'Vui lòng nhập KHÓA TRUY CẬP của bạn',
      addBedrockSK: 'KHÓA BÍ MẬT',
      bedrockSKMessage: 'Vui lòng nhập KHÓA BÍ MẬT của bạn',
      bedrockRegion: 'Vùng AWS',
      bedrockRegionMessage: 'Vui lòng chọn!',
      'us-east-1': 'US East (N. Virginia)',
      'us-west-2': 'US West (Oregon)',
      'ap-southeast-1': 'Asia Pacific (Singapore)',
      'ap-northeast-1': 'Asia Pacific (Tokyo)',
      'eu-central-1': 'Europe (Frankfurt)',
      'us-gov-west-1': 'AWS GovCloud (US-West)',
      'ap-southeast-2': 'Asia Pacific (Sydney)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Vui lòng nhập ID bí mật của bạn',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Vui lòng nhập Khóa bí mật của bạn',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Vui lòng nhập ID bí mật của bạn',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Vui lòng nhập Khóa bí mật của bạn',
      SparkModelNameMessage: 'Vui lòng chọn mô hình Spark',
      addSparkAPIPassword: 'Mật khẩu API Spark',
      SparkAPIPasswordMessage: 'vui lòng nhập mật khẩu API của bạn',
      addSparkAPPID: 'ID Ứng dụng Spark',
      SparkAPPIDMessage: 'vui lòng nhập APPID của bạn',
      addSparkAPISecret: 'Mật khẩu API Spark',
      SparkAPISecretMessage: 'vui lòng nhập Mật khẩu API của bạn',
      addSparkAPIKey: 'Khóa API Spark',
      SparkAPIKeyMessage: 'vui lòng nhập Khóa API của bạn',
      yiyanModelNameMessage: 'Vui lòng nhập tên mô hình',
      addyiyanAK: 'Khóa API yiyan',
      yiyanAKMessage: 'Vui lòng nhập KHÓA API của bạn',
      addyiyanSK: 'Khóa bí mật yiyan',
      yiyanSKMessage: 'Vui lòng nhập KHÓA BÍ MẬT của bạn',
      FishAudioModelNameMessage:
        'Vui lòng đặt tên cho mô hình tổng hợp giọng nói của bạn',
      addFishAudioAK: 'Khóa API Fish Audio',
      addFishAudioAKMessage: 'Vui lòng nhập KHÓA API của bạn',
      addFishAudioRefID: 'ID Tham chiếu Fish Audio',
      addFishAudioRefIDMessage:
        'Vui lòng nhập ID Tham chiếu (để trống để sử dụng mô hình mặc định).',
      GoogleModelIDMessage: 'Vui lòng nhập ID mô hình của bạn!',
      addGoogleProjectID: 'ID Dự án',
      GoogleProjectIDMessage: 'Vui lòng nhập ID Dự án của bạn',
      addGoogleServiceAccountKey:
        'Khóa Tài khoản Dịch vụ (Để trống nếu bạn sử dụng Ứng dụng mặc định Tín hiệu)',
      GoogleServiceAccountKeyMessage:
        'Vui lòng nhập Khóa Tài khoản Dịch vụ Google Cloud ở định dạng base64',
      addGoogleRegion: 'Vùng Google Cloud',
      GoogleRegionMessage: 'Vui lòng nhập Vùng Google Cloud',
      modelProvidersWarn: `Vui lòng thêm cả mô hình nhúng và LLM trong <b>Cài đặt > Nhà cung cấp mô hình</b> trước tiên. Sau đó, đặt chúng trong 'Cài đặt mô hình hệ thống'.`,
      apiVersion: 'Phiên bản API',
      apiVersionMessage: 'Vui lòng nhập phiên bản API',
      add: 'Thêm',
      updateDate: 'Ngày cập nhật',
      role: 'Vai trò',
      invite: 'Mời',
      agree: 'Đồng ý',
      refuse: 'Từ chối',
      teamMembers: 'Thành viên nhóm',
      joinedTeams: 'Nhóm đã tham gia',
      bedrockModelNameMessage: `Vui lòng nhập tên model của bạn!`,
      sureDelete: `Bạn có chắc chắn muốn xóa thành viên này không?`,
      quit: `Rời khỏi`,
      sureQuit: `Bạn có chắc chắn muốn rời khỏi nhóm mà bạn đã tham gia không?`,
      FishAudioAKMessage: `Vui lòng nhập KEY API`,
      FishAudioRefIDMessage: `Vui lòng nhập ID của model tham chiếu (để trống để sử dụng model mặc định)`,
    },
    message: {
      registered: 'Đã đăng ký!',
      logout: 'Đăng xuất',
      logged: 'Đã đăng nhập!',
      pleaseSelectChunk: 'Vui lòng chọn khối!',
      modified: 'Đã sửa đổi',
      created: 'Đã tạo',
      deleted: 'Đã xóa',
      renamed: 'Đã đổi tên',
      operated: 'Đã thao tác',
      updated: 'Đã cập nhật',
      uploaded: 'Đã tải lên',
      200: 'Máy chủ trả về dữ liệu được yêu cầu thành công.',
      201: 'Tạo hoặc sửa đổi dữ liệu thành công.',
      202: 'Một yêu cầu đã được xếp hàng trong nền (tác vụ không đồng bộ).',
      204: 'Dữ liệu đã bị xóa thành công.',
      400: 'Có lỗi trong yêu cầu được đưa ra và máy chủ không tạo hoặc sửa đổi dữ liệu.',
      401: 'Người dùng không có quyền (token, tên người dùng, mật khẩu sai).',
      403: 'Người dùng được ủy quyền nhưng bị cấm truy cập.',
      404: 'Yêu cầu được thực hiện cho một bản ghi không tồn tại và máy chủ không thực hiện thao tác.',
      406: 'Định dạng được yêu cầu không khả dụng.',
      410: 'Tài nguyên được yêu cầu đã bị xóa vĩnh viễn và sẽ không khả dụng nữa.',
      413: 'Tổng kích thước của các tệp được tải lên cùng một lúc quá lớn.',
      422: 'Khi tạo đối tượng, lỗi xác thực đã xảy ra.',
      500: 'Đã xảy ra lỗi máy chủ, vui lòng kiểm tra máy chủ.',
      502: 'Lỗi cổng.',
      503: 'Dịch vụ không khả dụng và máy chủ đang quá tải tạm thời hoặc đang được bảo trì.',
      504: 'Hết thời gian chờ cổng.',
      requestError: 'Lỗi yêu cầu',
      networkAnomalyDescription:
        'Mạng của bạn có sự bất thường và bạn không thể kết nối với máy chủ.',
      networkAnomaly: 'Bất thường mạng',
      hint: 'Gợi ý',
    },
    fileManager: {
      name: 'Tên',
      uploadDate: 'Ngày tải lên',
      knowledgeBase: 'Cơ sở kiến thức',
      size: 'Kích thước',
      action: 'Hành động',
      addToKnowledge: 'Liên kết đến Cơ sở kiến thức',
      pleaseSelect: 'Vui lòng chọn',
      newFolder: 'Thư mục mới',
      file: 'Tệp',
      uploadFile: 'Tải tệp lên',
      directory: 'Thư mục',
      uploadTitle: 'Nhấp hoặc kéo thả tệp vào khu vực này để tải lên',
      uploadDescription:
        'Hỗ trợ tải lên đơn hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu công ty hoặc các tệp bị cấm khác.',
      local: 'Tải lên cục bộ',
      s3: 'Tải lên S3',
      preview: 'Xem trước',
      fileError: 'Lỗi tệp',
      uploadLimit:
        'Kích thước tệp không được vượt quá 10M và tổng số tệp không được vượt quá 128',
      destinationFolder: 'Thư mục đích',
    },
    flow: {
      cite: 'Dẫn nguồn',
      citeTip: 'Mẹo dẫn nguồn',
      name: 'Tên',
      nameMessage: 'Vui lòng nhập tên',
      description: 'Mô tả',
      examples: 'Ví dụ',
      to: 'Đến',
      msg: 'Tin nhắn',
      messagePlaceholder: 'tin nhắn',
      messageMsg: 'Vui lòng nhập tin nhắn hoặc xóa trường này.',
      addField: 'Thêm trường',
      addMessage: 'Thêm tin nhắn',
      loop: 'Lặp',
      loopTip:
        'Lặp là giới hạn trên của số lần lặp của thành phần hiện tại, khi số lần lặp vượt quá giá trị của vòng lặp, có nghĩa là thành phần không thể hoàn thành tác vụ hiện tại, vui lòng tối ưu hóa lại tác nhân',
      yes: 'Có',
      no: 'Không',
      key: 'Khóa',
      componentId: 'ID Thành phần',
      add: 'Thêm',
      operation: 'hoạt động',
      run: 'Chạy',
      save: 'Lưu',
      title: 'ID:',
      beginDescription: 'Đây là nơi bắt đầu luồng công việc.',
      answerDescription: `Một thành phần đóng vai trò như giao diện giữa người và bot, nhận đầu vào của người dùng và hiển thị phản hồi của tác nhân.`,
      retrievalDescription: `Một thành phần truy xuất thông tin từ một cơ sở kiến thức được chỉ định và trả về 'Phản hồi trống' nếu không tìm thấy thông tin. Đảm bảo chọn đúng cơ sở kiến thức.`,
      generateDescription: `Một thành phần nhắc LLM tạo phản hồi. Đảm bảo lời nhắc được đặt chính xác.`,
      categorizeDescription: `Một thành phần sử dụng LLM để phân loại đầu vào của người dùng thành các danh mục được xác định trước. Đảm bảo bạn chỉ định tên, mô tả và ví dụ cho mỗi danh mục, cùng với thành phần tiếp theo tương ứng.`,
      relevantDescription: `Một thành phần sử dụng LLM để đánh giá xem đầu ra phía thượng nguồn có liên quan đến truy vấn mới nhất của người dùng hay không. Đảm bảo bạn chỉ định thành phần tiếp theo cho mỗi kết quả đánh giá.`,
      rewriteQuestionDescription: `Một thành phần tinh chỉnh truy vấn của người dùng nếu không thể truy xuất thông tin liên quan từ cơ sở kiến thức. Nó lặp lại quá trình này cho đến khi đạt đến giới hạn lặp trên được xác định trước. Đảm bảo phía thượng nguồn của nó là 'Liên quan' và phía hạ nguồn là 'Truy xuất'.`,
      messageDescription:
        "Một thành phần gửi đi một tin nhắn tĩnh. Nếu nhiều tin nhắn được cung cấp, nó sẽ chọn ngẫu nhiên một tin nhắn để gửi. Đảm bảo phía hạ nguồn của nó là 'Trả lời', thành phần giao diện.",
      keywordDescription: `Một thành phần truy xuất N kết quả tìm kiếm hàng đầu từ đầu vào của người dùng. Đảm bảo giá trị TopN được đặt chính xác trước khi sử dụng.`,
      switchDescription: `Một thành phần đánh giá các điều kiện dựa trên đầu ra của các thành phần trước đó và định hướng luồng thực thi cho phù hợp. Nó cho phép logic phân nhánh phức tạp bằng cách xác định các trường hợp và chỉ định hành động cho mỗi trường hợp hoặc hành động mặc định nếu không đáp ứng bất kỳ điều kiện nào.`,
      wikipediaDescription: `Thành phần này được sử dụng để lấy kết quả tìm kiếm từ wikipedia.org. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.`,
      promptText: `Vui lòng tóm tắt các đoạn văn sau. Cẩn thận với các số, đừng bịa ra. Các đoạn văn như sau:
               {input}
        Nội dung ở trên là nội dung bạn cần tóm tắt.`,
      createGraph: 'Tạo tác nhân',
      createFromTemplates: 'Tạo từ mẫu',
      retrieval: 'Truy xuất',
      generate: 'Sinh',
      answer: 'Trả lời',
      categorize: 'Phân loại',
      relevant: 'Liên quan',
      rewriteQuestion: 'Viết lại câu hỏi',
      rewrite: 'Viết lại',
      begin: 'Bắt đầu',
      message: 'Tin nhắn',
      blank: 'Trống',
      createFromNothing: 'Tạo tác nhân từ đầu',
      addItem: 'Thêm mục',
      addSubItem: 'Thêm mục con',
      nameRequiredMsg: 'Cần có tên',
      nameRepeatedMsg: 'Tên không được trùng lặp',
      keywordExtract: 'Từ khóa',
      keywordExtractDescription: `Một thành phần trích xuất các từ khóa từ truy vấn của người dùng, với Top N xác định số lượng từ khóa cần trích xuất.`,
      baidu: 'Baidu',
      baiduDescription: `Thành phần này được sử dụng để lấy kết quả tìm kiếm từ www.baidu.com. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.`,
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Một thành phần truy xuất kết quả tìm kiếm từ duckduckgo.com, với TopN xác định số lượng kết quả tìm kiếm. Nó bổ sung cho các cơ sở kiến thức hiện có.',
      channel: 'Kênh',
      channelTip: `Thực hiện tìm kiếm văn bản hoặc tìm kiếm tin tức trên đầu vào của thành phần`,
      text: 'Văn bản',
      news: 'Tin tức',
      messageHistoryWindowSize: 'Cửa sổ lịch sử tin nhắn',
      messageHistoryWindowSizeTip:
        'Kích thước cửa sổ của lịch sử cuộc hội thoại cần LLM xem. Càng lớn càng tốt. Nhưng hãy cẩn thận với chiều dài nội dung tối đa của LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      pubMedDescription:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://pubmed.ncbi.nlm.nih.gov/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh. Email là một trường bắt buộc.',
      email: 'Email',
      emailTip:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://pubmed.ncbi.nlm.nih.gov/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh. Email là một trường bắt buộc.',
      arXiv: 'ArXiv',
      arXivDescription:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://arxiv.org/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.',
      sortBy: 'Sắp xếp theo',
      submittedDate: 'Ngày gửi',
      lastUpdatedDate: 'Ngày cập nhật gần đây',
      relevance: 'Liên quan',
      google: 'Google',
      googleDescription:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://www.google.com/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N và SerpApi API key xác định số lượng kết quả tìm kiếm bạn cần điều chỉnh.',
      bing: 'Bing',
      bingDescription:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://www.bing.com/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N và Bing Subscription-Key xác định số lượng kết quả tìm kiếm bạn cần điều chỉnh.',
      apiKey: 'API KEY',
      country: 'Quốc gia',
      language: 'Ngôn ngữ',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://scholar.google.com/. Thông thường, nó hoạt động như một phần bổ sung cho các cơ sở kiến thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.',
      yearLow: 'Năm thấp',
      yearHigh: 'Năm cao',
      patents: 'Bằng sáng chế',
      data: 'Dữ liệu',
      deepL: 'DeepL',
      deepLDescription:
        'Thành phần này được sử dụng để dịch thuật từ https://www.deepl.com/. Thông thường, nó cung cấp kết quả dịch thuật chuyên biệt hơn.',
      authKey: 'Khóa xác thực',
      sourceLang: 'Ngôn ngữ nguồn',
      targetLang: 'Ngôn ngữ đích',
      gitHub: 'GitHub',
      githubDescription:
        'Thành phần này được sử dụng để tìm kiếm kho lưu trữ từ https://github.com/. Top N xác định số lượng kết quả tìm kiếm cần điều chỉnh.',
      baiduFanyi: 'Baidu Fanyi',
      baiduFanyiDescription:
        'Thành phần này được sử dụng để dịch thuật từ https://fanyi.baidu.com/. Thông thường, nó cung cấp kết quả dịch thuật chuyên biệt hơn.',
      appid: 'App ID',
      secretKey: 'Khóa bí mật',
      domain: 'Lĩnh vực',
      transType: 'Loại dịch',
      baiduSecretKeyOptions: {
        translate: 'Dịch thuật tổng hợp',
        fieldtranslate: 'Dịch thuật chuyên ngành',
      },
      baiduDomainOptions: {
        it: 'Công nghệ thông tin',
        finance: 'Tài chính và kinh tế',
        machinery: 'Sản xuất máy móc',
        senimed: 'Y sinh học',
        novel: 'Văn học trực tuyến',
        academic: 'Bài báo học thuật',
        aerospace: 'Hàng không vũ trụ',
        wiki: 'Khoa học nhân văn và xã hội',
        news: 'Tin tức và thông tin',
        law: 'Luật pháp và quy định',
        contract: 'Hợp đồng',
      },
      baiduSourceLangOptions: {
        auto: 'Tự động phát hiện',
        zh: 'Tiếng Trung',
        en: 'Tiếng Anh',
        yue: 'Tiếng Quảng Đông',
        wyw: 'Hán văn',
        jp: 'Tiếng Nhật',
        kor: 'Tiếng Hàn',
        fra: 'Tiếng Pháp',
        spa: 'Tiếng Tây Ban Nha',
        th: 'Tiếng Thái',
        ara: 'Tiếng Ả Rập',
        ru: 'Tiếng Nga',
        pt: 'Tiếng Bồ Đào Nha',
        de: 'Tiếng Đức',
        it: 'Tiếng Ý',
        el: 'Tiếng Hy Lạp',
        nl: 'Tiếng Hà Lan',
        pl: 'Tiếng Ba Lan',
        bul: 'Tiếng Bulgaria',
        est: 'Tiếng Estonia',
        dan: 'Tiếng Đan Mạch',
        fin: 'Tiếng Phần Lan',
        cs: 'Tiếng Séc',
        rom: 'Tiếng Romania',
        slo: 'Tiếng Slovenia',
        swe: 'Tiếng Thụy Điển',
        hu: 'Tiếng Hungary',
        cht: 'Tiếng Trung Phồn thể',
        vie: 'Tiếng Việt',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Thành phần này được sử dụng để lấy thông tin thời tiết từ https://www.qweather.com/. Bạn có thể lấy thời tiết, chỉ số, chất lượng không khí.',
      lang: 'Ngôn ngữ',
      type: 'Loại',
      webApiKey: 'Web API key',
      userType: 'Loại người dùng',
      timePeriod: 'Kỳ hạn',
      qWeatherLangOptions: {
        zh: 'Giản thể Trung Quốc',
        'zh-hant': 'Phồn thể Trung Quốc',
        en: 'Tiếng Anh',
        de: 'Tiếng Đức',
        es: 'Tiếng Tây Ban Nha',
        fr: 'Tiếng Pháp',
        it: 'Tiếng Ý',
        ja: 'Tiếng Nhật',
        ko: 'Tiếng Hàn',
        ru: 'Tiếng Nga',
        hi: 'Tiếng Hindi',
        th: 'Tiếng Thái',
        ar: 'Tiếng Ả Rập',
        pt: 'Tiếng Bồ Đào Nha',
        bn: 'Tiếng Bengal',
        ms: 'Tiếng Mã Lai',
        nl: 'Tiếng Hà Lan',
        el: 'Tiếng Hy Lạp',
        la: 'Tiếng Latin',
        sv: 'Tiếng Thụy Điển',
        id: 'Tiếng Indonesia',
        pl: 'Tiếng Ba Lan',
        tr: 'Tiếng Thổ Nhĩ Kỳ',
        cs: 'Tiếng Séc',
        et: 'Tiếng Estonia',
        vi: 'Tiếng Việt',
        fil: 'Tiếng Philippines',
        fi: 'Tiếng Phần Lan',
        he: 'Tiếng Do Thái',
        is: 'Tiếng Iceland',
        nb: 'Tiếng Na Uy',
      },
      qWeatherTypeOptions: {
        weather: 'Dự báo thời tiết',
        indices: 'Chỉ số cuộc sống thời tiết',
        airquality: 'Chất lượng không khí',
      },
      qWeatherUserTypeOptions: {
        free: 'Người đăng ký miễn phí',
        paid: 'Người đăng ký trả phí',
      },
      qWeatherTimePeriodOptions: {
        now: 'Bây giờ',
        '3d': '3 ngày',
        '7d': '7 ngày',
        '10d': '10 ngày',
        '15d': '12 ngày',
        '30d': '30 ngày',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Thành phần này truy vấn kết quả từ cơ sở dữ liệu quan hệ tương ứng thông qua các câu lệnh SQL. Hỗ trợ MySQL, PostgreSQL, MariaDB.',
      dbType: 'Loại cơ sở dữ liệu',
      database: 'Cơ sở dữ liệu',
      username: 'Tên người dùng',
      host: 'Máy chủ',
      port: 'Cổng',
      password: 'Mật khẩu',
      switch: 'Chuyển đổi',
      logicalOperator: 'Toán tử logic',
      switchOperatorOptions: {
        equal: 'bằng',
        notEqual: 'không bằng',
        gt: 'Lớn hơn',
        ge: 'Lớn hơn hoặc bằng',
        lt: 'Nhỏ hơn',
        le: 'Nhỏ hơn hoặc bằng',
        contains: 'Chứa',
        notContains: 'Không chứa',
        startWith: 'Bắt đầu bằng',
        endWith: 'Kết thúc bằng',
        empty: 'Trống',
        notEmpty: 'Không trống',
      },
      switchLogicOperatorOptions: {
        and: 'Và',
        or: 'Hoặc',
      },
      operator: 'Toán tử',
      value: 'Giá trị',
      useTemplate: 'Sử dụng mẫu này',
      wenCai: 'WenCai',
      queryType: 'Loại truy vấn',
      wenCaiDescription:
        'Thành phần này có thể được sử dụng để lấy thông tin về nhiều lĩnh vực tài chính, bao gồm nhưng không giới hạn ở cổ phiếu, quỹ, v.v...',
      wenCaiQueryTypeOptions: {
        stock: 'cổ phiếu',
        zhishu: 'chỉ số',
        fund: 'quỹ',
        hkstock: 'Cổ phiếu Hồng Kông',
        usstock: 'Thị trường chứng khoán Mỹ',
        threeboard: 'Thị trường OTC mới',
        conbond: 'Trái phiếu chuyển đổi',
        insurance: 'bảo hiểm',
        futures: 'hàng tương lai',
        lccp: 'Tài chính',
        foreign_exchange: 'Ngoại tệ',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Thành phần này có thể được sử dụng để lấy thông tin tin tức cho cổ phiếu tương ứng từ trang web Eastmoney.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Thành phần này truy vấn thông tin về công ty dựa trên ký hiệu mã chứng khoán được cung cấp.',
      crawler: 'Trình thu thập dữ liệu',
      crawlerDescription:
        'Thành phần này có thể được sử dụng để thu thập mã nguồn HTML từ URL được chỉ định.',
      proxy: 'Proxy',
      crawlerResultOptions: {
        html: 'Html',
        markdown: 'Markdown',
        content: 'Nội dung',
      },
      extractType: 'Loại trích xuất',
      info: 'Thông tin',
      history: 'Lịch sử',
      financials: 'Tài chính',
      balanceSheet: 'Bảng cân đối kế toán',
      cashFlowStatement: 'Báo cáo lưu chuyển tiền tệ',
      jin10: 'Jin10',
      jin10Description:
        'Thành phần này có thể được sử dụng để truy cập thông tin trong lĩnh vực tài chính từ Nền tảng Mở Jin10, bao gồm tin tức nhanh, lịch, báo giá, tham khảo.',
      flashType: 'Loại flash',
      filter: 'Bộ lọc',
      contain: 'Chứa',
      calendarType: 'Loại lịch',
      calendarDatashape: 'Hình dạng dữ liệu lịch',
      symbolsDatatype: 'Kiểu dữ liệu biểu tượng',
      symbolsType: 'Loại biểu tượng',
      jin10TypeOptions: {
        flash: 'Tin tức nhanh',
        calendar: 'Lịch',
        symbols: 'Báo giá',
        news: 'tham khảo',
      },
      jin10FlashTypeOptions: {
        '1': 'Tin tức thị trường',
        '2': 'Tin tức tương lai',
        '3': 'Tin tức Mỹ-Hồng Kông',
        '4': 'Tin tức A-Share',
        '5': 'Tin tức hàng hóa & ngoại hối',
      },
      jin10CalendarTypeOptions: {
        cj: 'Lịch dữ liệu kinh tế vĩ mô',
        qh: 'Lịch tương lai',
        hk: 'Lịch thị trường chứng khoán Hồng Kông',
        us: 'Lịch thị trường chứng khoán Mỹ',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Dữ liệu',
        event: 'Sự kiện',
        holiday: 'Ngày lễ',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Báo giá hàng hóa',
        FOREX: 'Báo giá ngoại hối',
        FUTURE: 'Báo giá thị trường quốc tế',
        CRYPTO: 'Báo giá tiền điện tử',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Danh sách hàng hóa',
        quotes: 'Báo giá thị trường mới nhất',
      },
      concentrator: 'Bộ tập trung',
      concentratorDescription:
        'Một thành phần nhận đầu ra từ thành phần thượng nguồn và chuyển nó làm đầu vào cho các thành phần hạ lưu.',
      tuShare: 'TuShare',
      tuShareDescription:
        'Thành phần này có thể được sử dụng để lấy thông tin tin tức tài chính từ các trang web tài chính chính thống, hỗ trợ nghiên cứu ngành và định lượng.',
      tuShareSrcOptions: {
        sina: 'Sina',
        wallstreetcn: 'wallstreetcn',
        '10jqka': 'Straight flush',
        eastmoney: 'Eastmoney',
        yuncaijing: 'YUNCAIJING',
        fenghuang: 'FENGHUANG',
        jinrongjie: 'JRJ',
      },
      token: 'Token',
      src: 'Nguồn',
      startDate: 'Ngày bắt đầu',
      endDate: 'Ngày kết thúc',
      keyword: 'Từ khóa',
      note: 'Ghi chú',
      noteDescription: 'Ghi chú',
      notePlaceholder: 'Vui lòng nhập ghi chú',
      invoke: 'Gọi',
      invokeDescription:
        'Thành phần này có thể gọi hàm từ xa. Đặt đầu ra của các thành phần khác làm tham số hoặc đặt các tham số hằng để gọi các hàm từ xa.',
      url: 'Url',
      method: 'Phương pháp',
      timeout: 'Thời gian chờ',
      headers: 'Tiêu đề',
      cleanHtml: 'Làm sạch HTML',
      cleanHtmlTip:
        'Nếu phản hồi được định dạng HTML và chỉ muốn nội dung chính, hãy bật nó lên.',
      reference: 'Tham khảo',
      input: 'Đầu vào',
      output: 'Đầu ra',
      parameter: 'Tham số',
      howUseId: 'Cách sử dụng ID tác nhân?',
      content: 'Nội dung',
      operationResults: 'Kết quả hoạt động',
      autosaved: 'Tự động lưu',
      optional: 'Tùy chọn',
      pasteFileLink: 'Dán liên kết tệp',
      testRun: 'Chạy thử nghiệm',
      template: 'Mẫu',
      templateDescription: `Thành phần này được sử dụng để sắp chữ đầu ra của nhiều thành phần khác nhau.`,
      arXivTip: `Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://arxiv.org/. Thông thường, nó hoạt động như một phần bổ sung cho cơ sở tri thức. Top N chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.`,
      googleTip: `Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://www.google.com/. Thông thường, nó hoạt động như một phần bổ sung cho cơ sở tri thức. Top N và khóa API SerpApi chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.`,
      bingTip: `Thành phần này được sử dụng để lấy kết quả tìm kiếm từ https://www.bing.com/. Thông thường, nó hoạt động như một phần bổ sung cho cơ sở tri thức. Top N và khóa đăng ký Bing chỉ định số lượng kết quả tìm kiếm bạn cần điều chỉnh.`,
      gitHubDescription: `Thành phần này được sử dụng để tìm kiếm các kho lưu trữ từ https://github.com/. Top N chỉ định số lượng kết quả tìm kiếm cần điều chỉnh.`,
      flow: `Quy trình làm việc`,
      emailDescription: 'Gửi email đến địa chỉ đã chỉ định',
      toEmail: 'Email người nhận',
      smtpServerRequired: 'Vui lòng nhập địa chỉ máy chủ SMTP',
      emailContent: 'Nội dung',
      smtpServer: 'SMTP Server',
      smtpPort: 'SMTP Port',
      senderEmailRequired: 'Vui lòng nhập email người gửi',
      authCodeRequired: 'Vui lòng nhập mã xác thực',
      toEmailRequired: 'Vui lòng nhập email người nhận',
      emailContentRequired: 'Vui lòng nhập nội dung email',
      emailSentSuccess: 'Email đã được gửi thành công',
      emailSentFailed: 'Không gửi được email',
      jsonFormatTip:
        'Thành phần thượng nguồn phải cung cấp chuỗi JSON theo định dạng sau:',
      emailComponent: 'Email',
      senderEmail: 'Người gửi Email',
      authCode: 'Mã xác minh',
      senderName: 'Tên người gửi',
      jsonUploadContentErrorMessage: 'lỗi tệp json',
      contentTip: 'content: Nội dung email (Tùy chọn)',
      subjectTip: 'subject: Tiêu đề email (Tùy chọn)',
      jsonUploadTypeErrorMessage: 'Vui lòng tải lên tệp json',
      dynamicParameters: 'Tham số động',
      emailSubject: 'Tiêu đề email',
      ccEmail: 'Email CC',
      toEmailTip: 'to_email: Email người nhận (Bắt buộc)',
      ccEmailTip: 'cc_email: Email CC (Tùy chọn)',
    },
    footer: {
      profile: 'All rights reserved @ React',
    },
    layout: {
      file: 'tệp',
      knowledge: 'kiến thức',
      chat: 'trò chuyện',
    },
  },
};
